import moment from "moment"
import "moment-timezone"
import branding from "../branding/branding"

export const getTimezoneOffset = () => {
    const eventTimezoneName = branding.configuration.eventTimeZone
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const eventTimeOffset = (moment.tz(eventTimezoneName) as unknown as { _offset: number })._offset
    const localTimeOffset = localTimezoneName
        ? (moment.tz(localTimezoneName) as unknown as { _offset: number })._offset
        : moment(moment.tz(moment.tz.guess()).format()).utcOffset()

    let timezoneMinutesDifference = Math.abs(eventTimeOffset - localTimeOffset)

    if (eventTimeOffset > localTimeOffset) timezoneMinutesDifference = -timezoneMinutesDifference

    return timezoneMinutesDifference
}

export function isTimeEqual(time1: string, time2: string): boolean {
    // Split the times into hours and minutes
    const [hours1, minutes1] = time1.split(":").map(Number)
    const [hours2, minutes2] = time2.split(":").map(Number)

    // Return true if both hours and minutes are equal
    return hours1 === hours2 && minutes1 === minutes2
}

export function isTimeBefore(time1: string, time2: string): boolean {
    // Split the times into hours and minutes
    const [hours1, minutes1] = time1.split(":").map(Number)
    const [hours2, minutes2] = time2.split(":").map(Number)

    // Compare hours first
    if (hours1 < hours2) {
        return true
    } else if (hours1 > hours2) {
        return false
    }

    // If hours are equal, compare minutes
    return minutes1 <= minutes2
}
